/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const groutColors = ["White", "Black", "Silver", "Off White", "Other"];
const fixtureFinishes = [
  "Brushed Nickel",
  "Oil Rubbed Bronze",
  "Polished Aluminum",
];
const tilePatterns = [
  "Horizontal Brick",
  "Vertical Brick",
  "Straight Herringbone",
  "Herringbone",
  "Horizontal Stacked",
  "Vertical Stacked",
  "Vertical Offset",
];
const trimStyles = ["Stepped", "Flat", "Sculpted", "Tile"];
const sinkTypes = ["Pedestal", "Top-Mount", "Under-Mount"];
const countertopTypes = [
  "Quartz",
  "Granite",
  "Marble",
  "Concrete",
  "Solid Surface",
];
const floorTypes = ["LVT", "tile", "hardWood", "carpet"];

export function BathQuoteForm() {
  const [customGroutColor, setCustomGroutColor] = useState(false);
  const [customDoorTrim, setCustomDoorTrim] = useState(false);

  const priceTile = (values) => {
    let cost = 0;
    if (
      ["Straight Herringbone", "Herringbone"].includes(values.showerTilePattern)
    ) {
      cost += Number(values.tileSqFt) * 65;
    } else {
      cost += Number(values.tileSqFt) * 60;
    }

    return cost;
  };

  const priceSchluter = (values) => {
    let roundedLinearFt = Math.ceil(values.schluterFeet / 8) * 8;
    return (roundedLinearFt / 8) * 53;
  };

  const priceShower = (values) => {
    let cost = 2000;
    if (values.panOptions && values.panOptions !== "prefab pan") {
      if (values.panOptions === "custom pan") {
        cost += Number(values.panSqFt) * 100;
      } else {
        cost += Number(values.panSqFt) * 200;
      }
    } else {
      cost += Number(values.prefabPanCost);
    }
    if (values.glassOption !== "showerRod") {
      cost += values.glassOption === "barnDoor" ? 1000 : 3500;
    }
    cost += Number(values.fixturePrice);
    return cost;
  };

  const priceTub = (values) => {
    let cost = 1500;
    if (values.glassOption !== "showerRod") {
      cost += values.glassOption === "barnDoor" ? 1000 : 3500;
    }
    cost += Number(values.tubCost);
    cost += Number(values.fixturePrice);
    return cost;
  };

  const priceBathEnclosure = (values) => {
    let cost = 0;
    if (values.tubOrShower) {
      if (values.tubOrShower === "tub") {
        cost += priceTub(values);
      } else {
        cost += priceShower(values);
      }
    }
    cost += priceTile(values);
    cost += priceSchluter(values);

    return cost;
  };

  const priceFlooring = (values) => {
    if (values.floorType === "LVT") {
      if (
        ["Straight Herringbone", "Herringbone"].includes(
          values.floorTilePattern
        )
      ) {
        return Number(values.floorSqFt) * 20;
      }
      return Number(values.floorSqFt) * 15;
    } else if (values.floorType === "tile") {
      if (
        ["Straight Herringbone", "Herringbone"].includes(
          values.floorTilePattern
        )
      ) {
        return Number(values.floorSqFt) * 55;
      }
      return Number(values.floorSqFt) * 50;
    } else if (values.floorType === "hardWood") {
      if (
        ["Straight Herringbone", "Herringbone"].includes(
          values.floorTilePattern
        )
      ) {
        return Number(values.floorSqFt) * 25;
      }
      return Number(values.floorSqFt) * 20;
    } else {
      return Number(values.floorSqFt) * 12;
    }
  };

  const priceTrim = (values) => {
    if (customDoorTrim) {
      return (
        Number(values.baseboardTrimFeet) * 10 + Number(values.doorCasings) * 300
      );
    }
    return (
      Number(values.baseboardTrimFeet) * 10 + Number(values.doorCasings) * 150
    );
  };

  const priceVanity = (values) => {
    let cost = 0;
    const [countertopWidth, countertopHeight] = values.countertopDims
      .split("x")
      .map(Number);

    if (values.sinkType === "Top-mount") {
      cost += 150 + Number(values.sinkCost);
    } else if (values.sinkType === "Under-mount") {
      cost += 300 + Number(values.sinkCost);
    } else if (values.sinkType === "Pedestal") {
      cost += 500 + Number(values.sinkCost);
    }

    if (values.resetVanity) {
      cost += 300;
    } else if (Number(values.vanityCost) > 0) {
      cost += 300 + Number(values.vanityCost);
    }
    if (values.countertopDims) {
      cost += ((countertopWidth * countertopHeight) / 144) * 50;
    }
    return cost;
  };

  const priceToilet = (values) => {
    if (values.newToilet) {
      return Number(values.toiletCost) + 300;
    }
    return 300;
  };

  const priceAddOns = (values) => {
    if (values.miscAddOn.length) {
      return Number(values.addOnCost);
    }
    return 0;
  };

  const priceBathRemodel = (values) => {
    console.log(
      Number(
        (
          (priceToilet(values) +
            priceFlooring(values) +
            priceBathEnclosure(values) +
            priceTrim(values) +
            priceVanity(values) +
            priceAddOns(values)) *
          1.1
        ).toFixed(2)
      )
    );
    return Number(
      (
        (priceToilet(values) +
          priceFlooring(values) +
          priceBathEnclosure(values) +
          priceTrim(values) +
          priceVanity(values) +
          priceAddOns(values)) *
        1.1
      ).toFixed(2)
    );
  };

  return (
    <Box sx={{ maxWidth: "100vw" }}>
      <Formik
        initialValues={{
          // Bath Enclosure
          tubOrShower: "",
          tileSqFt: "",
          showerTilePattern: "",
          groutColor: "",
          customGrout: "",
          enclosureTileImg: "",
          enclosureTileName: "",
          fixtureFinish: "",
          fixtureName: "",
          fixtureImage: "",
          fixturePrice: "",
          glassOption: "",
          panOptions: "",
          panSqFt: "",
          prefabPanCost: "",
          prefabPanName: "",
          storage: "",
          storageQty: "",
          storageDims: "",
          schluterFeet: "",
          tubCost: "",
          tubName: "",

          // Flooring
          flooringImg: "",
          flooringName: "",
          floorSqFt: "",
          floorTilePattern: "",
          floorType: "",
          floorGroutColor: "",
          customFloorGrout: "",
          thresholdTransition: "",

          // Trim
          baseboardStyle: "",
          baseboardFeet: "",
          baseboardTrimFeet: "0",
          doorTrimStyle: "",
          customDoorTrimDesc: "",
          doorCasings: "0",

          // Vanity
          vanityDims: "",
          vanityName: "",
          vanityCost: "0",
          vanityImg: "",
          countertopDims: "",
          countertopType: "",
          resetVanity: "",
          sinkType: "",
          sinkCost: "",
          sinkImg: "",
          sinkName: "",
          vanityFixtureFinish: "",

          // Toilet
          resetToilet: false,
          newToilet: false,
          toiletName: "",
          toiletImg: "",
          toiletCost: "",

          // Misc Add-ons
          miscAddOn: "",
          addOnDisc: "",
          addOnCost: "",
        }}
        onSubmit={(values) => priceBathRemodel(values)}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                maxWidth: 600,
                margin: "auto",
                p: 3,
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: "white",
              }}
            >
              <Typography variant="h4" sx={{ textAlign: "center", mb: 3 }}>
                Bathroom
              </Typography>

              {/* Bath Enclosure Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Bath Enclosure
              </Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Tub or Shower</InputLabel>
                <Field as={Select} name="tubOrShower">
                  <MenuItem value="tub">Tub</MenuItem>
                  <MenuItem value="shower">Shower</MenuItem>
                </Field>
              </FormControl>
              {values.tubOrShower && (
                <Box sx={{ mb: 2, mt: -1 }}>
                  <Typography variant="h6" sx={{ mb: -1 }}>
                    Glass Door
                  </Typography>
                  <Field name="glassOption">
                    {({ field, form }) => (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.glassOption === "barnDoor"}
                              onChange={() =>
                                form.setFieldValue(
                                  "glassOption",
                                  values.glassOption === "barnDoor"
                                    ? ""
                                    : "barnDoor"
                                )
                              }
                            />
                          }
                          label="Kohler Levity Barn Door"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.glassOption === "customGlass"}
                              onChange={() =>
                                form.setFieldValue(
                                  "glassOption",
                                  values.glassOption === "customGlass"
                                    ? ""
                                    : "customGlass"
                                )
                              }
                            />
                          }
                          label="Custom Glass"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.glassOption === "showerRod"}
                              onChange={() =>
                                form.setFieldValue(
                                  "glassOption",
                                  values.glassOption === "showerRod"
                                    ? ""
                                    : "showerRod"
                                )
                              }
                            />
                          }
                          label="Shower Rod"
                        />
                      </>
                    )}
                  </Field>
                </Box>
              )}
              {values.tubOrShower === "shower" && (
                <Box>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Pan Options</InputLabel>
                    <Field as={Select} name="panOptions">
                      <MenuItem value="custom pan">Poured Pan</MenuItem>
                      <MenuItem value="custom pan curbless">
                        Curbless Pan
                      </MenuItem>
                      <MenuItem value="prefab pan">Prefab Pan</MenuItem>
                    </Field>
                  </FormControl>
                </Box>
              )}
              {(values.panOptions === "custom pan" ||
                values.panOptions === "custom pan curbless") && (
                <Box>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Pan Sqft"
                    name="panSqFt"
                    type="number"
                    sx={{ mb: 2 }}
                  />
                </Box>
              )}
              {values.panOptions === "prefab pan" && (
                <Box>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Prefab Pan Name"
                    name="prefabPanName"
                    sx={{ mb: 2 }}
                  />
                  <Field
                    as={TextField}
                    fullWidth
                    label="Prefab Pan Cost"
                    name="prefabPanCost"
                    type="number"
                    sx={{ mb: 2 }}
                  />
                </Box>
              )}
              {values.tubOrShower === "tub" && (
                <Box>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Tub Name"
                    name="tubName"
                    sx={{ mb: 2 }}
                  />
                  <Field
                    as={TextField}
                    fullWidth
                    label="Tub Cost"
                    name="tubCost"
                    type="number"
                    sx={{ mb: 2 }}
                  />
                </Box>
              )}
              <Field
                as={TextField}
                fullWidth
                label="Tile Square Feet"
                name="tileSqFt"
                type="number"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Tile Image Link"
                name="enclosureTileImg"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Tile Name and Description"
                name="enclosureTileName"
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Tile Set Pattern</InputLabel>
                <Field as={Select} name="showerTilePattern">
                  {tilePatterns.map((pattern) => (
                    <MenuItem key={pattern} value={pattern}>
                      {pattern}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Grout Color</InputLabel>
                <Field
                  as={Select}
                  name="groutColor"
                  onChange={(e) => {
                    handleChange(e);
                    setCustomGroutColor(e.target.value === "Other");
                  }}
                >
                  {groutColors.map((color) => (
                    <MenuItem key={color} value={color}>
                      {color}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              {customGroutColor && (
                <Field
                  as={TextField}
                  fullWidth
                  label="Custom Grout Color"
                  name="customGrout"
                  sx={{ mb: 2 }}
                />
              )}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Fixture Finish</InputLabel>
                <Field as={Select} name="fixtureFinish">
                  {fixtureFinishes.map((finish) => (
                    <MenuItem key={finish} value={finish}>
                      {finish}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <Field
                as={TextField}
                fullWidth
                label="Fixture Name"
                name="fixtureName"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Fixture Price"
                name="fixturePrice"
                type="number"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Fixture Image Link"
                name="fixtureImg"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Schluter (Linear Feet)"
                name="schluterFeet"
                type="number"
                sx={{ mb: 2 }}
              />

              {/* Flooring Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Flooring
              </Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Flooring Type</InputLabel>
                <Field as={Select} name="floorType">
                  {floorTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <Field
                as={TextField}
                fullWidth
                label="Flooring Square Feet"
                name="floorSqFt"
                type="number"
                sx={{ mb: 2 }}
              />
              {values.floorType && values.floorType !== "carpet" && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Flooring Pattern</InputLabel>
                  <Field as={Select} name="floorTilePattern">
                    {tilePatterns.map((pattern) => (
                      <MenuItem key={pattern} value={pattern}>
                        {pattern}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              )}
              {values.floorType === "tile" && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Grout Color</InputLabel>
                  <Field
                    as={Select}
                    name="floorGroutColor"
                    onChange={(e) => {
                      handleChange(e);
                      setCustomGroutColor(e.target.value === "Other");
                    }}
                  >
                    {groutColors.map((color) => (
                      <MenuItem key={color} value={color}>
                        {color}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              )}
              <Field
                as={TextField}
                fullWidth
                label="Flooring Image Link"
                name="flooringImg"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Flooring Name and Description"
                name="flooringName"
                sx={{ mb: 2 }}
              />
              {customGroutColor && (
                <Field
                  as={TextField}
                  fullWidth
                  label="Custom Grout Color"
                  name="customFloorGrout"
                  sx={{ mb: 2 }}
                />
              )}
              <Field
                as={TextField}
                fullWidth
                label="Threshold Transition"
                name="thresholdTransition"
                sx={{ mb: 2 }}
              />

              {/* Trim Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Trim
              </Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Baseboard Style</InputLabel>
                <Field as={Select} name="baseboardStyle">
                  {trimStyles.map((style) => (
                    <MenuItem key={style} value={style}>
                      {style}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <Field
                as={TextField}
                fullWidth
                label="Baseboard Trim (Linear Feet)"
                name="baseboardTrimFeet"
                type="number"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Baseboard (Linear Feet)"
                name="baseboardFeet"
                type="number"
                sx={{ mb: 2 }}
              />

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Door Trim Style</InputLabel>
                <Field
                  as={Select}
                  name="doorTrimStyle"
                  onChange={(e) => {
                    handleChange(e);
                    setCustomDoorTrim(e.target.value === "Custom");
                  }}
                >
                  {trimStyles.concat("Custom").map((style) => (
                    <MenuItem key={style} value={style}>
                      {style}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              {customDoorTrim && (
                <Field
                  as={TextField}
                  fullWidth
                  label="Custom Door Trim Description"
                  name="customDoorTrimDesc"
                  sx={{ mb: 2 }}
                />
              )}
              <Field
                as={TextField}
                fullWidth
                label="Door Casings"
                name="doorCasings"
                type="number"
                sx={{ mb: 2 }}
              />

              {/* Vanity Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Vanity
              </Typography>
              <FormControlLabel
                control={<Field as={Checkbox} name="resetVanity" />}
                label="Reset Existing Vanity"
              />
              <Field
                as={TextField}
                fullWidth
                label="Vanity Dimensions"
                name="vanityDims"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Vanity Name"
                name="vanityName"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Vanity Image"
                name="vanityImg"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Vanity Cost"
                name="vanityCost"
                type="number"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Countertop Dimensions"
                name="countertopDims"
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Countertop Type</InputLabel>
                <Field as={Select} name="countertopType">
                  {[
                    "Quartz",
                    "Granite",
                    "Marble",
                    "Concrete",
                    "Solid Surface",
                  ].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Sink Type</InputLabel>
                <Field as={Select} name="sinkType">
                  {["Pedestal", "Top-Mount", "Under-Mount", "Bowl"].map(
                    (type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    )
                  )}
                </Field>
              </FormControl>
              <Field
                as={TextField}
                fullWidth
                label="Sink Name"
                name="sinkName"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Sink Image Link"
                name="sinkName"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Sink Cost"
                name="sinkCost"
                type="number"
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Fixture Finish</InputLabel>
                <Field as={Select} name="vanityFixtureFinish">
                  {[
                    "Brushed Nickel",
                    "Oil Rubbed Bronze",
                    "Polished Aluminum",
                  ].map((finish) => (
                    <MenuItem key={finish} value={finish}>
                      {finish}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>

              {/* toilet section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Toilet
              </Typography>
              <FormControlLabel
                control={<Field as={Checkbox} name="resetToilet" />}
                label="Reset Existing Toilet"
              />
              <FormControlLabel
                control={<Field as={Checkbox} name="newToilet" />}
                label="New Toilet"
              />
              {values.newToilet && (
                <Box>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Toilet Name"
                    name="toiletName"
                    sx={{ mb: 2 }}
                  />
                  <Field
                    as={TextField}
                    fullWidth
                    label="Toilet Image Link"
                    name="toiletImg"
                    sx={{ mb: 2 }}
                  />
                  <Field
                    as={TextField}
                    fullWidth
                    label="Toilet Cost"
                    name="toiletCost"
                    sx={{ mb: 2 }}
                  />
                </Box>
              )}

              {/* Misc Add-ons */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Misc Add-ons
              </Typography>
              <FormControlLabel
                control={<Field as={Checkbox} name="miscAddOn" />}
                label="Misc Add-ons"
              />
              <Field
                as={TextField}
                fullWidth
                label="Enter Add-ons"
                name="addOnDisc"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Add-on Cost"
                name="addOnCost"
                type="number"
                sx={{ mb: 2 }}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 3 }}
              >
                Get Quote
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
