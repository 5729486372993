/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { BathQuoteForm } from "../components/BathQuoteForm";
import { KitchenQuoteForm } from "../components/KitchenQuoteForm";
import { palette } from "../theme";

const groutColors = ["White", "Black", "Silver", "Off White", "Other"];
const fixtureFinishes = [
  "Brushed Nickel",
  "Oil Rubbed Bronze",
  "Polished Aluminum",
];
const tilePatterns = [
  "Horizontal Brick",
  "Vertical Brick",
  "Straight Herringbone",
  "Herringbone",
  "Horizontal Stacked",
  "Vertical Stacked",
  "Vertical Offset",
];
const trimStyles = ["Stepped", "Flat", "Sculpted", "Tile"];
const sinkTypes = ["Pedestal", "Top-Mount", "Under-Mount", "Bowl"];
const countertopTypes = [
  "Quartz",
  "Granite",
  "Marble",
  "Concrete",
  "Solid Surface",
];

export function QuotePage() {
  const [bathQuote, setBathQuote] = useState(false);
  const [kitchenQuote, setKitchenQuote] = useState(false);

  return (
    <Box sx={{ width: "100vw" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <Typography variant="h1" sx={{ color: palette.white }}>
          Generate Quote
        </Typography>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "space-around",
            marginY: 10,
          }}
        >
          <Button
            onClick={() => {
              setKitchenQuote(!kitchenQuote);
            }}
          >
            Kitchen
          </Button>
          <Button
            onClick={() => {
              setBathQuote(!bathQuote);
            }}
          >
            Bath
          </Button>
        </Box>
      </Box>
      {bathQuote && <BathQuoteForm />}
      {kitchenQuote && <KitchenQuoteForm />}
    </Box>
  );
}
