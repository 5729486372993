import { Header } from "../components/Header";
import kitchenFiller from "../media/kitchen_filler.jpg";
import bath_filler from "../media/bath_filler.jpg";
import {
  Box,
  Typography,
  Button,
  Modal,
  Icon,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { palette } from "../theme";
import Kitchen from "../media/kitchen_service.jpg";
import Bath from "../media/bath_service.jpg";
import Powerwash from "../media/powerwash_service.jpg";
import Landscape from "../media/landscape_service.jpg";
import Flooring from "../media/flooring_service.jpg";
import Fireplace from "../media/fireplace_service.jpg";
import Carpentry from "../media/carpentry_service.jpg";
import { Footer } from "../components/Footer";
import { Banner } from "../components/Banner";

export function ServicesPage() {
  const products = [
    {
      title: "Kitchens Remodeling",
      description:
        "Transform your kitchen into a modern, functional space tailored to your lifestyle.",
      imageUrl: Kitchen,
    },
    {
      title: "Bath Remodeling",
      description:
        "Upgrade your bathroom with luxurious designs and high-quality finishes.",
      imageUrl: Bath,
    },
    {
      title: "Flooring",
      description:
        "Enhance your home with durable, stylish flooring options for every room.",
      imageUrl: Flooring,
    },
    {
      title: "Fireplace Remodeling",
      description:
        "Create a stunning focal point with custom fireplace designs and upgrades.",
      imageUrl: Fireplace,
    },
    {
      title: "Carpentry",
      description:
        "Custom woodwork and craftsmanship to add beauty and functionality to your home.",
      imageUrl: Carpentry,
    },
    {
      title: "Landscaping",
      description:
        "Beautiful outdoor spaces designed to complement your home and lifestyle.",
      imageUrl: Landscape,
    },
    {
      title: "Power Washing",
      description:
        "Restore the beauty of your home’s exterior with professional power washing services.",
      imageUrl: Powerwash,
    },
  ];

  return (
    <Box sx={{ maxWidth: "100vw" }}>
      <Header />
      <Banner imgLeft={kitchenFiller} imgRight={bath_filler} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: palette.grey3,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: palette.darkBlue,
            fontWeight: "bold",
            textShadow: "0 1px 2px rgba(0,0,0,0.6)",
            marginY: 4,
          }}
        >
          Our Services
        </Typography>
        <Box sx={{ maxWidth: "90vw", marginBottom: 6 }}>
          <Grid container spacing={3} justifyContent="center">
            {products.map((product, index) => {
              const isLast = index === products.length - 1;
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  sx={isLast && products.length % 3 === 1 ? { mx: "auto" } : {}}
                >
                  <Card
                    sx={{
                      height: { md: 400, xs: 600 },
                      boxShadow: 3,
                      transition: "transform 0.3s",
                      "&:hover": {
                        transform: "translateY(-5px)",
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="75%"
                      image={product.imageUrl}
                      alt={product.title}
                    />
                    <CardContent sx={{ textAlign: "center" }}>
                      <Typography
                        variant="h2"
                        sx={{
                          fontWeight: "bold",
                          marginBottom: 1,
                          color: palette.darkBlue,
                        }}
                      >
                        {product.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ color: palette.darkBlue }}
                      >
                        {product.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
