import { Box } from "@mui/material";

export function Banner({ imgLeft, imgRight }) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "70vh",
        overflow: "hidden",
        position: "relative",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          zIndex: 1,
        }}
      />
      <Box
        component="img"
        src={imgLeft}
        alt="kitchen"
        sx={{
          width: { xs: "100%", sm: "50%" },
          height: "100%",
          objectFit: "cover",
        }}
      />
      <Box
        component="img"
        src={imgRight}
        alt="bathroom"
        sx={{
          display: { xs: "none", sm: "block" },
          width: { sm: "50%" },
          height: "100%",
          objectFit: "cover",
        }}
      />
    </Box>
  );
}
