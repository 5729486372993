import * as React from "react";
import { Box, Typography, Button, Modal, Icon } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ContactForm } from "../components/ContactForm";
import { palette } from "../theme";

export function ContactModal({ open, setOpen, handleClose, handleOpen }) {
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { md: "fit-content", xs: "100%" },
            borderRadius: 3,
          }}
        >
          <CloseIcon
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              position: "absolute",
              top: "1%",
              left: "95%",
              "&:hover": {
                transition: "background 2ms ease-in-out",
                color: palette.starfishOrange,
                cursor: "pointer",
              },
            }}
          />
          <ContactForm />
        </Box>
      </Modal>
      <Button
        onClick={(open) => {
          setOpen(true);
        }}
        sx={{
          position: "absolute",
          top: "90%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { md: 300, xs: "50%" },
          height: 60,
          zIndex: 2,
          boxShadow: "0px 5px 8px 2px #ffffff",
          "&:hover": {
            background: `linear-gradient(white, white) padding-box,
                        ${palette.starfishOrange} border-box`,
            transition: "background 2ms ease-in-out",
            color: palette.starfishOrange,
          },
        }}
      >
        <Typography variant="h4">Get A Quote</Typography>
      </Button>
    </Box>
  );
}
