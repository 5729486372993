/** @jsxImportSource @emotion/react */
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { palette } from "../theme";
import kitchenFiller from "../media/kitchen_filler_3.jpg";
import bathFiller from "../media/bath_filler_3.jpg";
import { Banner } from "../components/Banner";
import logo from "../media/vector_logo.png";

export function AboutPage() {
  const coreValues = [
    {
      title: "Customer First",
      desc: "We put your needs and vision at the center of everything we do, ensuring clear and consistent communication throughout your project. From the initial consultation to the final walkthrough, your satisfaction drives every decision we make.",
    },
    {
      title: "Quality Craftsmanship",
      desc: "Our experienced team takes pride in delivering exceptional results, using only the highest-quality materials and techniques. Whether it's a small renovation or a full-scale remodel, we are committed to precision, durability, and beauty in every detail.",
    },
    {
      title: "Integrity",
      desc: "We believe in honest communication, fair pricing, and ethical business practices. You can trust us to provide straightforward project timelines, accurate estimates, and a transparent process so there are no surprises along the way.",
    },
    {
      title: "Innovation",
      desc: "We stay ahead of industry trends, continuously exploring new technologies, design styles, and innovative materials. Our goal is to offer creative, forward-thinking solutions that are both functional and aesthetically beautiful for your home.",
    },
  ];
  return (
    <Box sx={{ maxWidth: "100vw", overflowX: "hidden" }}>
      <Header />
      <Banner imgLeft={kitchenFiller} imgRight={bathFiller} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: palette.grey3,
        }}
      >
        <Box sx={{ maxWidth: "100%", mx: "auto" }}>
          <Typography
            variant="h1"
            sx={{
              color: palette.darkBlue,
              fontWeight: "bold",
              textShadow: "0 1px 2px rgba(0,0,0,0.6)",
              marginY: 4,
              textAlign: "center",
            }}
          >
            Our Story
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              flexDirection: { md: "row", xs: "column" },
              justifyContent: "space-around",
              marginBottom: 3,
            }}
          >
            <Box sx={{ width: { md: "40%", xs: "100%" } }}>
              <Box sx={{ mb: 6 }}>
                <Typography variant="body3">
                  Good Bones Renovations was founded with one goal: to help
                  homeowners achieve their dream homes with expert craftsmanship
                  and high-quality materials. With decades of experience in
                  kitchen remodeling, bathroom renovations, and custom home
                  upgrades, we have proudly served thousands of happy customers
                  across the region.
                </Typography>
              </Box>
              <Box>
                <Typography variant="body3">
                  Our dedicated team of designers, project managers, and skilled
                  tradespeople work collaboratively to bring your vision to
                  life. We believe every project deserves the highest attention
                  to detail, from initial consultation to final installation.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: { md: "30%", xs: "100%" } }}>
              <img
                src={logo}
                css={{
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "white", py: 8 }}>
        <Box sx={{ maxWidth: "1200px", mx: "auto" }}>
          <Typography
            variant="h1"
            sx={{
              color: palette.darkBlue,
              fontWeight: "bold",
              textShadow: "0 1px 2px rgba(0,0,0,0.6)",
              marginBottom: 4,
              textAlign: "center",
            }}
          >
            Our Core Values
          </Typography>
          <Grid container spacing={6}>
            {coreValues.map((value, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    backgroundColor: palette.lightGrey,
                    height: 300,
                    boxShadow: 3,
                    borderRadius: 3,
                    transition: "transform 0.3s",
                    "&:hover": { transform: "translateY(-5px)" },
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.25rem",
                        marginY: 1,
                      }}
                    >
                      {value.title}
                    </Typography>
                    <Typography variant="body2">{value.desc}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
