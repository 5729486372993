import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Banner } from "../components/Banner";
import { Header } from "../components/Header";
import { ContactForm } from "../components/ContactForm";
import kitchenFiller from "../media/kitchen_filler_2.jpg";
import bathFiller from "../media/bath_filler_2.jpg";
import { palette } from "../theme";
import { Footer } from "../components/Footer";
import serviceArea from "../media/service_area.png";

export function ContactPage() {
  const theme = useTheme();

  return (
    <Box sx={{ maxWidth: "100vw" }}>
      <Header />
      <Banner imgLeft={kitchenFiller} imgRight={bathFiller} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: palette.grey3,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: palette.darkBlue,
            fontWeight: "bold",
            textShadow: "0 1px 2px rgba(0,0,0,0.6)",
            marginY: 4,
          }}
        >
          Contact Us
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            mx: "auto",
            marginBottom: 6,
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "space-around",
          }}
        >
          <Card
            sx={{
              backgroundColor: theme.palette.grey[100],
              boxShadow: 3,
              padding: 3,
              borderRadius: 3,
              width: { md: "50%", xs: "100%" },
              display: "flex",
              justifyContent: "space-around",
              flexDirection: { md: "row", xs: "column" },
              marginBottom: { xs: 6, md: 0 },
            }}
          >
            <CardContent>
              <Typography
                variant="h2"
                sx={{ textDecoration: "underline", marginBottom: 2 }}
              >
                Contact Information
              </Typography>
              <Typography variant="h3" sx={{ mb: 1 }}>
                <strong>Phone:</strong> (720) 425-1163
              </Typography>
              <Typography variant="h3" sx={{ mb: 1 }}>
                <strong>Email:</strong> goodbonesrenovations@gmail.com
              </Typography>
              <Typography
                variant="h2"
                sx={{ mt: 3, mb: 1, textDecoration: "underline" }}
              >
                Hours of Operation
              </Typography>
              <Typography variant="body1">
                Monday - Friday: 8:00 AM - 5:00 PM
              </Typography>
              <Typography variant="body1">
                Saturday - Sunday: 10:00 AM - 5:00 PM
              </Typography>
            </CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h2">Service Area</Typography>
              <CardMedia
                component="img"
                image={serviceArea}
                alt={"service area"}
              />
            </Box>
          </Card>
          <ContactForm />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
