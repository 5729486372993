/** @jsxImportSource @emotion/react */
import emailjs from "emailjs-com";
import {
  Box,
  Link,
  Typography,
  Button,
  Select,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";
import { palette } from "../theme";
import { Formik, ErrorMessage, Field } from "formik";

export function ContactForm() {
  const sendEmail = (values) => {
    emailjs
      .send("service_wkr8g9h", "template_9o0hlsc", values, "mACvWnbh1yASSnJ4M")
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          console.error("FAILED...", error);
          alert("Failed to send email. Please try again later.");
        }
      );
  };

  const projectTypeOptions = () => {
    const services = [
      "kitchen",
      "bath",
      "flooring",
      "fireplace",
      "custom carpentry",
      "land scaping",
      "power washing",
    ];
    return services.map((service, index) => {
      const text = service
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
      return (
        <MenuItem key={index} value={text}>
          {text}
        </MenuItem>
      );
    });
  };
  return (
    <Box
      sx={{
        backgroundColor: palette.grey3,
        width: "fit-content",
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
        width: "fit-content",
        alignItems: "center",
        boxShadow: "inset 3px -1px 12px 0px black",
      }}
    >
      <Typography variant="h2" sx={{ marginTop: 2 }}>
        Contact Us
      </Typography>
      <Formik
        initialValues={{
          description: "",
          email: "",
          firstName: "",
          lastName: "",
          phone: "",
          projectType: "",
          zip: "",
        }}
        validate={(values) => {
          const errors = {};
          const requiredFields = [
            "firstName",
            "lastName",
            "zip",
            "phone",
            "projectType",
            "email",
            "description",
          ];

          requiredFields.forEach((field) => {
            if (!values[field]) {
              errors[field] = "Required";
            }
          });

          if (
            values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (values.phone && values.phone.length < 10) {
            errors.phone = "Please enter valid phone number";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          sendEmail(values); // Call sendEmail function
          setTimeout(() => {
            setSubmitting(false);
            resetForm();
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Box
                    sx={{
                      width: 200,
                      height: 30,
                      borderRadius: 5,
                      display: "block",
                      margin: "10px auto 0",
                    }}
                  >
                    <label htmlFor="firstName">First Name</label>
                    <TextField
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      size="small"
                      variant="outlined"
                      sx={{ backgroundColor: "#ffffff" }}
                    />
                    <ErrorMessage
                      name="firstName"
                      render={(msg) => (
                        <Typography sx={{ color: "red" }}>{msg}</Typography>
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: 200,
                      height: 30,
                      borderRadius: 5,
                      display: "block",
                      margin: "40px auto 0",
                    }}
                  >
                    <label htmlFor="phone">Phone Number</label>
                    <TextField
                      type="tel"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      size="small"
                      variant="outlined"
                      sx={{ backgroundColor: "#ffffff" }}
                    />
                    <ErrorMessage
                      name="phone"
                      render={(msg) => (
                        <Typography sx={{ color: "red" }}>{msg}</Typography>
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: 200,
                      height: 30,
                      borderRadius: 5,
                      display: "block",
                      margin: "40px auto 0",
                    }}
                  >
                    <label htmlFor="zip">Zip Code</label>
                    <TextField
                      type="text"
                      name="zip"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.zip}
                      size="small"
                      variant="outlined"
                      sx={{ backgroundColor: "#ffffff" }}
                    />
                    <ErrorMessage
                      name="zip"
                      render={(msg) => (
                        <Typography sx={{ color: "red" }}>{msg}</Typography>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Box
                    sx={{
                      width: 200,
                      height: 30,
                      borderRadius: 5,
                      display: "block",
                      margin: "10px auto 0",
                    }}
                  >
                    <label htmlFor="lastName">Last Name</label>
                    <TextField
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      size="small"
                      variant="outlined"
                      sx={{ backgroundColor: "#ffffff" }}
                    />
                    <ErrorMessage
                      name="lastName"
                      render={(msg) => (
                        <Typography sx={{ color: "red" }}>{msg}</Typography>
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: 200,
                      height: 30,
                      borderRadius: 5,
                      display: "block",
                      margin: "40px auto 0",
                    }}
                  >
                    <label htmlFor="email">Email</label>
                    <TextField
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      size="small"
                      variant="outlined"
                      sx={{ backgroundColor: "#ffffff" }}
                    />
                    <ErrorMessage
                      name="email"
                      render={(msg) => (
                        <Typography sx={{ color: "red" }}>{msg}</Typography>
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: 200,
                      display: "block",
                      margin: "40px auto 0",
                    }}
                  >
                    <label htmlFor="projectType">Project Type</label>
                    <Select
                      sx={{
                        width: "100%",
                        height: 30,
                        backgroundColor: "#ffffff",
                      }}
                      name="projectType"
                      value={values.projectType}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      css={{ width: 196, height: 40, borderRadius: 5 }}
                    >
                      {projectTypeOptions()}
                    </Select>
                    <ErrorMessage
                      name="projectType"
                      render={(msg) => (
                        <Typography sx={{ color: "red" }}>{msg}</Typography>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "block",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "80%",
                      maxWidth: 600,
                      margin: "0 auto 0",
                    }}
                  >
                    <label htmlFor="description">Description</label>
                    <TextField
                      multiline
                      name="description"
                      rows="4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      sx={{
                        width: "100%",
                        resize: "vertical",
                        height: 100,
                        marginBottom: 3,
                        backgroundColor: "#ffffff",
                      }}
                    />
                    <ErrorMessage
                      name="description"
                      render={(msg) => (
                        <Typography sx={{ color: "red" }}>{msg}</Typography>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ textAlign: "center", marginY: 2 }}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    Object.keys(errors).length ||
                    !values.description
                  }
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
