/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const groutColors = ["White", "Black", "Silver", "Off White", "Other"];
const fixtureFinishes = [
  "Brushed Nickel",
  "Oil Rubbed Bronze",
  "Polished Aluminum",
];
const tilePatterns = [
  "Horizontal Brick",
  "Vertical Brick",
  "Straight Herringbone",
  "Herringbone",
  "Horizontal Stacked",
  "Vertical Stacked",
  "Vertical Offset",
];
const trimStyles = ["Stepped", "Flat", "Sculpted", "Tile"];
const sinkTypes = ["Pedestal", "Top-Mount", "Under-Mount", "Bowl"];
const countertopTypes = [
  "Quartz",
  "Granite",
  "Marble",
  "Concrete",
  "Solid Surface",
];

export function KitchenQuoteForm() {
  const [customGroutColor, setCustomGroutColor] = useState(false);
  const [customDoorTrim, setCustomDoorTrim] = useState(false);

  return (
    <Box sx={{ maxWidth: "100vw" }}>
      <Formik
        initialValues={{
          // Bath Enclosure
          tubOrShower: "",
          tileSqFt: "",
          tilePattern: "",
          groutColor: "",
          customGrout: "",
          fixtureFinish: "",
          storage: "",
          storageQty: "",
          storageDims: "",
          schluterFeet: "",

          // Flooring
          floorTileSqFt: "",
          floorTilePattern: "",
          floorGroutColor: "",
          customFloorGrout: "",
          thresholdTransition: "",

          // Trim
          baseboardStyle: "",
          baseboardFeet: "",
          doorTrimStyle: "",
          customDoorTrimDesc: "",
          doorTrimFeet: "",

          // Vanity
          vanityDims: "",
          countertopDims: "",
          countertopType: "",
          sinkType: "",
          vanityFixtureFinish: "",

          // Toilet
          resetToilet: false,
          newToilet: false,
        }}
        onSubmit={(values) => console.log("Submitted Values:", values)}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                maxWidth: 600,
                margin: "auto",
                p: 3,
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: "white",
              }}
            >
              <Typography variant="h4" sx={{ textAlign: "center", mb: 3 }}>
                Kitchen
              </Typography>

              {/* Bath Enclosure Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Bath Enclosure
              </Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Tub or Shower</InputLabel>
                <Field as={Select} name="tubOrShower">
                  <MenuItem value="tub">Tub</MenuItem>
                  <MenuItem value="shower">Shower</MenuItem>
                </Field>
              </FormControl>

              <Field
                as={TextField}
                fullWidth
                label="Tile Square Feet"
                name="tileSqFt"
                type="number"
                sx={{ mb: 2 }}
              />

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Tile Set Pattern</InputLabel>
                <Field as={Select} name="tilePattern">
                  {tilePatterns.map((pattern) => (
                    <MenuItem key={pattern} value={pattern}>
                      {pattern}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Grout Color</InputLabel>
                <Field
                  as={Select}
                  name="groutColor"
                  onChange={(e) => {
                    handleChange(e);
                    setCustomGroutColor(e.target.value === "Other");
                  }}
                >
                  {groutColors.map((color) => (
                    <MenuItem key={color} value={color}>
                      {color}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>

              {customGroutColor && (
                <Field
                  as={TextField}
                  fullWidth
                  label="Custom Grout Color"
                  name="customGrout"
                  sx={{ mb: 2 }}
                />
              )}

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Fixture Finish</InputLabel>
                <Field as={Select} name="fixtureFinish">
                  {fixtureFinishes.map((finish) => (
                    <MenuItem key={finish} value={finish}>
                      {finish}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>

              <Field
                as={TextField}
                fullWidth
                label="Schluter (Linear Feet)"
                name="schluterFeet"
                type="number"
                sx={{ mb: 2 }}
              />

              {/* Flooring Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Flooring
              </Typography>
              <Field
                as={TextField}
                fullWidth
                label="Tile Square Feet"
                name="floorTileSqFt"
                type="number"
                sx={{ mb: 2 }}
              />

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Tile Set Pattern</InputLabel>
                <Field as={Select} name="floorTilePattern">
                  {tilePatterns.map((pattern) => (
                    <MenuItem key={pattern} value={pattern}>
                      {pattern}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Grout Color</InputLabel>
                <Field
                  as={Select}
                  name="floorGroutColor"
                  onChange={(e) => {
                    handleChange(e);
                    setCustomGroutColor(e.target.value === "Other");
                  }}
                >
                  {groutColors.map((color) => (
                    <MenuItem key={color} value={color}>
                      {color}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>

              {customGroutColor && (
                <Field
                  as={TextField}
                  fullWidth
                  label="Custom Grout Color"
                  name="customFloorGrout"
                  sx={{ mb: 2 }}
                />
              )}

              <Field
                as={TextField}
                fullWidth
                label="Threshold Transition"
                name="thresholdTransition"
                sx={{ mb: 2 }}
              />

              {/* Trim Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Trim
              </Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Baseboard Style</InputLabel>
                <Field as={Select} name="baseboardStyle">
                  {trimStyles.map((style) => (
                    <MenuItem key={style} value={style}>
                      {style}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>

              <Field
                as={TextField}
                fullWidth
                label="Baseboard (Linear Feet)"
                name="baseboardFeet"
                type="number"
                sx={{ mb: 2 }}
              />

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Door Trim Style</InputLabel>
                <Field
                  as={Select}
                  name="doorTrimStyle"
                  onChange={(e) => {
                    handleChange(e);
                    setCustomDoorTrim(e.target.value === "Custom");
                  }}
                >
                  {trimStyles.concat("Custom").map((style) => (
                    <MenuItem key={style} value={style}>
                      {style}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>

              {customDoorTrim && (
                <Field
                  as={TextField}
                  fullWidth
                  label="Custom Door Trim Description"
                  name="customDoorTrimDesc"
                  sx={{ mb: 2 }}
                />
              )}

              <Field
                as={TextField}
                fullWidth
                label="Door Trim (Linear Feet)"
                name="doorTrimFeet"
                type="number"
                sx={{ mb: 2 }}
              />

              {/* Vanity Section */}
              <Typography variant="h6" sx={{ mt: 2 }}>
                Vanity
              </Typography>
              <Field
                as={TextField}
                fullWidth
                label="Vanity Dimensions"
                name="vanityDims"
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Countertop Dimensions"
                name="countertopDims"
                sx={{ mb: 2 }}
              />

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Countertop Type</InputLabel>
                <Field as={Select} name="countertopType">
                  {[
                    "Quartz",
                    "Granite",
                    "Marble",
                    "Concrete",
                    "Solid Surface",
                  ].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Sink Type</InputLabel>
                <Field as={Select} name="sinkType">
                  {["Pedestal", "Top-Mount", "Under-Mount", "Bowl"].map(
                    (type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    )
                  )}
                </Field>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Fixture Finish</InputLabel>
                <Field as={Select} name="vanityFixtureFinish">
                  {[
                    "Brushed Nickel",
                    "Oil Rubbed Bronze",
                    "Polished Aluminum",
                  ].map((finish) => (
                    <MenuItem key={finish} value={finish}>
                      {finish}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Toilet
              </Typography>
              <FormControlLabel
                control={<Field as={Checkbox} name="resetToilet" />}
                label="Reset Existing Toilet"
              />
              <FormControlLabel
                control={<Field as={Checkbox} name="newToilet" />}
                label="New Toilet"
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 3 }}
              >
                Get Quote
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
