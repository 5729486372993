import { createTheme } from "@mui/material";

export const palette = {
  amberOrange: "#F7931E",
  backgroundGray: "#E8EAEE",
  blueOpaque: "#54627C",
  darkBlue: "#1B2D51",
  gold: "#F4B321",
  green: "#65C728",
  grey1: "#E5E5E5",
  grey2: "#F9F9F9",
  grey3: "#E0E7ED",
  grey4: "#1A1A1A",
  lightGrey: "#E1E1E1 ",
  polishedPine: "#5F9582",
  secondaryRed: "#D63641",
  starfishOrange: "#F76241",
  success: "#65C728",
  white: "#FFFF",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: palette.darkBlue,
    },
    secondary: {
      main: palette.starfishOrange,
    },
    background: {
      default: palette.darkBlue,
    },
  },
  typography: {
    fontFamily: "Exo",
    body1: {
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeight: 400,
      color: palette.darkBlue,
    },
    body2: {
      fontFamily: "Exo",
      fontSize: 18,
      fontWeight: 400,
      color: palette.darkBlue,
    },
    body3: {
      fontFamily: "Exo",
      fontSize: 28,
      fontWeight: 400,
      color: palette.darkBlue,
    },
    h1: {
      fontSize: 50,
      fontWeight: "bold",
      color: palette.darkBlue,
    },
    h2: {
      fontSize: 32,
      fontWeight: "bold",
      color: palette.darkBlue,
    },
    h3: {
      fontSize: 28,
      fontWeight: "bold",
      fontFamily: "Exo",
      color: palette.darkBlue,
    },
    h4: {
      fontFamily: "Exo",
      fontSize: 20,
      fontWeight: 600,
      fontFamily: "Exo",
      color: palette.darkBlue,
    },
    h5: {
      fontFamily: "Exo",
      fontSize: 16,
      fontWeight: 600,
      color: palette.darkBlue,
    },
    h6: {
      fontFamily: "Exo",
      fontSize: 14,
      color: palette.darkBlue,
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: palette.white,
          borderRadius: 60,
          backgroundImage: `linear-gradient(218.12deg, ${palette.starfishOrange} 25.8%, ${palette.amberOrange} 133.27%)`,
          textTransform: "none",
          fontWeight: 600,
          padding: "10px 50px",
          "&:hover": {
            color: palette.darkBlue,
          },
          "&.Mui-disabled": {
            background: palette.lightGrey,
            color: palette.white,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:
            "linear-gradient(300.7deg, #1D3970 43.2%, #0B1529 119.28%)",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          color: palette.darkBlue,
          fontSize: 16,
        },
        a: {
          textDecoration: "none",
          color: palette.starfishOrange,
          cursor: "pointer",
          textDecoration: "underline",
        },
      },
    },
  },
});
