/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import {
  CssBaseline,
  styled,
  ThemeProvider,
  Box,
  CircularProgress,
} from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { theme } from "./theme";
import { HomePage } from "./pages/HomePage";
import { AboutPage } from "./pages/AboutPage";
import { ContactPage } from "./pages/ContactPage";
import { GalleryPage } from "./pages/GalleryPage";
import { ServicesPage } from "./pages/ServicesPage";
import { QuotePage } from "./pages/QuotePage";

import kitchenFiller from "./media/kitchen_filler.jpg";
import kitchenFiller2 from "./media/kitchen_filler_2.jpg";
import kitchenFiller3 from "./media/kitchen_filler_3.jpg";
import bathFiller from "./media/bath_filler.jpg";
import bathFiller2 from "./media/bath_filler_2.jpg";
import bathFiller3 from "./media/bath_filler_3.jpg";
import logo from "./media/vector_logo.png";

function App() {
  const AppContainer = styled("div")({
    overflowX: "hidden",
    width: "100vw",
    height: "100vh",
    position: "absolute",
  });

  const [imagesLoaded, setImagesLoaded] = useState(false);

  const imageSources = [
    kitchenFiller,
    kitchenFiller2,
    kitchenFiller3,
    bathFiller,
    bathFiller2,
    bathFiller3,
    logo,
  ];

  useEffect(() => {
    let loadedCount = 0;

    const handleImageLoad = () => {
      loadedCount++;
      if (loadedCount === imageSources.length) {
        setImagesLoaded(true);
      }
    };

    imageSources.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = handleImageLoad;
      img.onerror = handleImageLoad;
    });
  }, []);

  if (!imagesLoaded) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f0f0f0",
        }}
      />
    );
  }

  return (
    <AppContainer id="appContainer">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="*" element={<HomePage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/about-us" element={<AboutPage />} />
          {/* <Route path="/gallery" element={<GalleryPage />} /> */}
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/quote" element={<QuotePage />} />
        </Routes>
      </ThemeProvider>
    </AppContainer>
  );
}

export default App;
