/** @jsxImportSource @emotion/react */
import { Box, Link, Typography } from "@mui/material";
import { palette } from "../theme";
import logo from "../media/vector_logo.png";

export function Header() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        alignItems: { xs: "center" },
        justifyContent: "space-between",
        background: palette.grey3,
        height: "fit-content",
        paddingX: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: { md: "15vw", xs: "100vw" },
        }}
      >
        <a href={"/"}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </a>
      </Box>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Exo",
              fontSize: { md: 32, xs: 22 },
              fontWeight: 600,
              color: palette.darkBlue,
            }}
          >
            720-425-1163
          </Typography>
          <Typography
            sx={{
              fontFamily: "Exo",
              fontSize: { md: 32, xs: 22 },
              fontWeight: 600,
              color: palette.darkBlue,
            }}
          >
            GoodBonesRenovations@gmail.com
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            height: "100%",
            flexDirection: { md: "row", xs: "column" },
            alignItems: "center",
          }}
        >
          <Link
            href="/contact-us"
            sx={{ whiteSpace: "nowrap" }}
            variant="h2"
            color="secondary"
          >
            Contact Us
          </Link>
          <Link
            href="/about-us"
            sx={{ whiteSpace: "nowrap" }}
            variant="h2"
            color="secondary"
          >
            About Us
          </Link>
          <Link
            href="/services"
            sx={{ whiteSpace: "nowrap" }}
            variant="h2"
            color="secondary"
          >
            Our Services
          </Link>
          {/* <Link
            href="/gallery"
            sx={{ whiteSpace: "nowrap" }}
            variant="h2"
            color="secondary"
          >
            Photo Gallery
          </Link> */}
        </Box>
      </Box>
    </Box>
  );
}
