import * as React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  Button,
  Modal,
  Icon,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Header } from "../components/Header";
import bathroom from "../media/bath_main_image.jpg";
import kitchen from "../media/kitchen_main_image.jpg";
import logo from "../media/vector_logo.png";
import { ContactForm } from "../components/ContactForm";
import { palette } from "../theme";
import { ContactModal } from "../components/ContactModal";
import { Footer } from "../components/Footer";
import { Banner } from "../components/Banner";

export function HomePage() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ maxWidth: "100vw" }}>
      <Header />
      <Banner imgLeft={kitchen} imgRight={bathroom} />
      <Box
        sx={{
          position: "absolute",
          top: { md: "75%", xs: "110%" },
          left: "50%",
          transform: "translate(-50%)",
          zIndex: 2,
          width: "100%",
        }}
      >
        <ContactModal
          setOpen={setOpen}
          open={open}
          handleClose={handleClose}
          handleOpen={handleOpen}
        />
      </Box>
      <Box sx={{ width: "100%", backgroundColor: palette.grey3 }}>
        <Box>
          <Box
            sx={{
              padding: 5,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h1">A Passion for Creating Spaces</Typography>
            <Typography
              variant="h2"
              sx={{ width: { md: "60vw", xs: "80vw" }, marginTop: 4 }}
            >
              We specialize in bringing new life to homes and businesses through
              expert remodeling solutions. Whether you’re looking to refresh a
              single room or overhaul an entire property, our team delivers
              craftsmanship, efficiency, and attention to detail to create
              spaces that inspire.
            </Typography>
          </Box>

          <Grid container spacing={4} sx={{ padding: 5 }}>
            <Grid item xs={12} sm={4}>
              <Card
                sx={{
                  height: { md: 280, xs: "fitContent" },
                  paddingX: 3,
                  borderRadius: 3,
                  boxShadow: "3px -1px 12px 0px black",
                }}
              >
                <Typography variant="h2" sx={{ marginTop: 2, marginBottom: 3 }}>
                  Renovation and restoration
                </Typography>
                <Typography variant="body3" sx={{}}>
                  Whether you’re updating a single room or transforming your
                  entire home, we bring fresh ideas and expert craftsmanship to
                  every project. From modern makeovers to functional upgrades,
                  we create stylish and practical spaces tailored to your needs.
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card
                sx={{
                  height: { md: 280, xs: "fitContent" },
                  paddingX: 3,
                  borderRadius: 3,
                  boxShadow: "3px -1px 12px 0px black",
                }}
              >
                <Typography variant="h2" sx={{ marginTop: 2, marginBottom: 3 }}>
                  Continuous Support
                </Typography>
                <Typography variant="body3" sx={{}}>
                  Your project is our priority. From initial consultation to
                  final touches, we provide clear communication to ensure a
                  smooth remodeling experience.
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card
                sx={{
                  height: { md: 280, xs: "fitContent" },
                  paddingX: 3,
                  borderRadius: 3,
                  boxShadow: "3px -1px 12px 0px black",
                }}
              >
                <Typography variant="h2" sx={{ marginTop: 2, marginBottom: 3 }}>
                  Quality Craftsmanship
                </Typography>
                <Typography variant="body3" sx={{}}>
                  We take pride in delivering superior workmanship in every
                  project. Using the highest quality materials and expert
                  techniques, we ensure that your remodel not only looks great
                  but also stands the test of time.
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card
                sx={{
                  height: { md: 280, xs: "fitContent" },
                  paddingX: 3,
                  borderRadius: 3,
                  boxShadow: "3px -1px 12px 0px black",
                }}
              >
                <Typography variant="h2" sx={{ marginTop: 2, marginBottom: 3 }}>
                  Consulting
                </Typography>
                <Typography variant="body3" sx={{}}>
                  Not sure where to start? Our remodeling experts offer tailored
                  advice and design insights to help you make informed decisions
                  that align with your vision, budget, and timeline.
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card
                sx={{
                  height: { md: 280, xs: "fitContent" },
                  paddingX: 3,
                  borderRadius: 3,
                  boxShadow: "3px -1px 12px 0px black",
                }}
              >
                <Typography variant="h2" sx={{ marginTop: 2, marginBottom: 3 }}>
                  Project Management
                </Typography>
                <Typography variant="body3" sx={{}}>
                  We handle every aspect of your remodel. From planning and
                  budgeting to execution and final walkthroughs. Our streamlined
                  process ensures efficiency, transparency, and exceptional
                  results.
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card
                sx={{
                  height: { md: 280, xs: "fitContent" },
                  paddingX: 3,
                  borderRadius: 3,
                  boxShadow: "3px -1px 12px 0px black",
                }}
              >
                <Typography variant="h2" sx={{ marginTop: 2, marginBottom: 3 }}>
                  Custom Solutions
                </Typography>
                <Typography variant="body3" sx={{}}>
                  Every space is unique, and so are our remodeling solutions.
                  Whether it’s a kitchen, bathroom, basement, garage, or
                  whole-home renovation, we deliver customized designs that
                  reflect your style and enhance functionality.
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
