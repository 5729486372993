import { Box, Typography } from "@mui/material";
import { palette } from "../theme";
export function Footer() {
  return (
    <Box sx={{ width: "100%", textAlign: "center", marginY: 3 }}>
      <Typography variant="body3" sx={{ color: palette.white }}>
        <a href="contact-us" css={{ color: "red" }}>
          Contact us
        </a>{" "}
        today to get started on your dream renovation!
      </Typography>
    </Box>
  );
}
